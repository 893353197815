@import './colors';
@import './sizes';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: $gray-light-1;

  &.fullscreen {
    overflow: hidden;
  }
}

button {
  touch-action: manipulation;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white inset;
  font-size: 16px;
}

input::placeholder {
  color: rgba($black-rgb, 0.7) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.hidden {
  display: none !important;
}

.capitalized {
  text-transform: capitalize;
}

.all-caps {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.primary-color {
  color: $primary;
}

.btn-block {
  width: 100%;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* XS */
@media screen and (max-width: $xsMax) {
  .hidden-xs {
    display: none !important;
  }

  input,
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}

//#region Material

.cdk-overlay-container {
  z-index: 9999;
}

.mat-input-element {
  color: $table-font-color !important;
}

.mat-focused .mat-form-field-required-marker {
  color: $error;
}

.mat-form-field-prefix .mat-icon.color-picked {
  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 2px;
    border: 1px solid $black;
    border-radius: 100px;
    width: 17px;
    height: 17px;
  }
}

.mat-form-field-disabled .mat-form-field-prefix .mat-icon {
  opacity: 0.5;
}

.mat-raised-button {
  background: $white;
  color: $table-font-color;
}

.mat-toolbar .mat-button-disabled {
  background: none !important;
}

tr.mat-header-row {
  height: 46px !important;
  position: sticky;
}

.mat-dialog-container {
  background: $white;
  color: rgba($black-rgb, 0.87);
}

.mat-paginator {
  border: 1px solid $gray-light-3;
  border-top: none;
  background: $white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger,
.mat-select-value {
  color: $table-font-color;
}

.mat-checkbox .mat-ripple-element {
  background-color: $black;
}

.mat-checkbox-frame {
  border-color: rgba($black-rgb, 0.54);
}

.mat-pseudo-checkbox {
  color: rgba($black-rgb, 0.54);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: $primary;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba($black-rgb, 0.42);
}

.mat-form-field-label {
  color: rgba($black-rgb, 0.6);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after,
.mat-select-disabled .mat-select-value,
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow,
.mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-label {
  color: rgba($black-rgb, 0.38) !important;
}

.mat-slide-toggle-bar.mat-slide-toggle-bar,
.mat-slide-toggle-bar {
  background-color: rgba($black-rgb, 0.38);
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: $black;
}

.dark-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: $black;
}

.light-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: $primary;
}

.mat-select-arrow,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba($black-rgb, 0.54);
}

.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba($black-rgb, 0.26);
}

.mat-sort-header-arrow {
  color: $text-on-primary !important;
}

.mat-select-panel {
  background: $white;

  .mat-option {
    color: $table-font-color;

    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background: rgba($black-rgb, 0.04);
    }
  }
}

.mat-table {
  background: $white;
}

.mat-drawer-container {
  background-color: $gray-light-1;
  color: $table-font-color;
}

.mat-header-cell {
  background: $main;
  color: $text-on-primary;
}

.mat-cell,
.mat-footer-cell {
  color: $table-font-color;
}

.mat-paginator-sticky {
  bottom: 0;
  position: sticky;
  z-index: 10;
}

.mat-radio-outer-circle {
  border-color: rgba($black-rgb, 0.54);
}

.mat-hint {
  color: rgba($black-rgb, 0.6);
}

.mat-chip.mat-standard-chip {
  background-color: $gray-light-3;
  color: rgba($black, 0.87);

  .mat-chip-remove {
    color: rgba($black, 0.87);
  }
}

.light-theme {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: $gray-light-3;
    }
  }
}

.dark-theme {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: rgb($black-rgb, 0.12);
    }
  }
}

.mat-datepicker-content {
  background-color: $white;
  color: rgba($black-rgb, 0.87);
}

.mat-calendar-arrow {
  fill: rgba($black-rgb, 0.54);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba($black-rgb, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba($black-rgb, 0.87);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background: rgba($primary-rgb, 0.3);
}

.mat-calendar-body-selected {
  background: $primary;
  color: $text-on-primary;
}

.mat-menu-panel {
  background: $white;

  .mat-menu-item {
    color: $table-font-color;

    &.filled-btn {
      background: $primary;
      color: $text-on-primary;
    }
  }

  .mat-menu-item .mat-icon-no-color,
  .mat-menu-submenu-icon {
    color: rgba($black-rgb, 0.54);
  }
}

.mat-drawer {
  background: $white;
  color: $table-font-color;
}

.mat-tab-label,
.mat-tab-link {
  color: $table-font-color;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba($black-rgb, 0.12);
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: rgba($black-rgb, 0.12);

    .mat-form-field-outline-thick {
      color: rgba($black-rgb, 0.87);
    }
  }
}

.mat-column-id {
  width: 90px !important;
}

.mat-column-action {
  width: 240px !important;
  text-align: right;
  padding-right: 20px !important;
}

.mat-column-phone {
  word-break: break-word;
}

td.mat-column-donationCode,
td.mat-column-donation_code {
  color: $primary;
}

.mat-column-address-zip {
  width: 100px !important;
}

.mat-column-address-state {
  width: 100px !important;
}

.mat-tab-body-content {
  padding: 30px 0 0 0;
}

.mat-ink-bar {
  background-color: $primary !important;
}

.mat-divider {
  margin: 20px 0 !important;
}

td {
  .mat-mini-fab {
    background-color: $main !important;

    @media screen and (max-width: $xsMax) {
      &:not(.success):not(.warning) {
        background-color: $white !important;
        color: $main !important;
        box-shadow: none;
      }
    }

    &.success {
      background-color: $success !important;
    }

    &.warning {
      background-color: $error !important;
    }
  }
}

.mat-snack-bar-container {
  &.success {
    text-align: center;
    background-color: $success;
    color: $text-on-primary;
  }

  &.error {
    text-align: center;
    background-color: $error;
    color: $text-on-primary;
  }

  .mat-simple-snackbar {
    justify-content: center;
  }
}

.mat-vertical-content-container {
  padding: 30px 0;
}

.mat-step-icon {
  background-color: $primary !important;

  &.mat-step-icon-state-done {
    background-color: $success !important;
  }
}

.mat-checkbox {
  margin-bottom: 30px;

  .mat-checkbox-inner-container {
    height: 24px !important;
    width: 24px !important;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $primary !important;
    }
  }
}

.mat-radio-button {
  &.mat-accent {
    .mat-radio-inner-circle {
      color: $primary;
      background-color: $primary;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $primary;
      }

      .mat-radio-persistent-ripple,
      .mat-ripple-element {
        background-color: $primary;
      }
    }

    .mat-radio-ripple .mat-ripple-element {
      background-color: $primary !important;
    }
  }
}

.mat-button-disabled {
  opacity: 0.5;
  background-color: $white !important;
}

.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba($black-rgb, 0.32) !important;

  &.mat-primary {
    .mat-icon {
      color: rgba($white-rgb, 0.32) !important;
    }
  }
}

.mat-dialog-container {
  max-height: 100vh !important;

  @media screen and (max-width: $xsMax) {
    max-height: calc(100vh - 100px) !important;
  }
}

.mat-dialog-content {
  max-height: calc(100vh - 160px) !important;
  min-height: 50px;
  padding: 10px 24px !important;

  @media screen and (max-width: $xsMax) {
    max-height: calc(100vh - 260px) !important;
  }
}

.mat-dialog-actions button.mat-primary {
  background-color: $primary;
}

.mat-form-field {
  &.no-padding {
    .mat-form-field-wrapper {
      padding: 0 !important;
    }
  }
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $primary;
  }

  .mat-slide-toggle-thumb {
    background-color: $primary-darken-10;
  }
}

.mat-expansion-panel {
  background: $white;
  color: rgba($black-rgb, 0.87);
}

.mat-expansion-panel-header {
  color: rgba($black-rgb, 0.87);
  font-size: 15px;
  line-height: 18px;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba($black-rgb, 0.54);
}

.mat-tab-header-pagination-chevron {
  border-color: $black;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba($black-rgb, 0.38);
}

.mat-card {
  background: $white;
}

.mat-icon {
  &.success {
    color: $success;
  }
  &.warning {
    color: $warn;
  }
  &.error {
    color: $error;
  }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: $primary;
}

.mat-button-toggle-checked {
  background-color: $main !important;

  button {
    background-color: $main !important;
    color: $text-on-primary !important;
  }
}

.mat-button-toggle-button {
  background-color: $white !important;
  color: $table-font-color !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba($black-rgb, 0.12);
  background-color: $white !important;
}

.mat-button-toggle-appearance-standard {
  background-color: $white !important;
}

.mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: solid 1px rgba($black-rgb, 0.12);
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
  .mat-expansion-panel-header:hover {
  background: $white;
}

.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: $primary;
}

.mat-slider-horizontal.mat-slider-has-ticks:hover:not(.mat-slider-disabled) {
  .mat-slider-wrapper::after {
    height: 6px;
    top: -2px;
  }
  .mat-slider-ticks-container {
    height: 6px;
    top: -2px;
  }
  .mat-slider-ticks {
    height: 6px;
  }
}

.mat-badge-content {
  background: $main;
  color: $text-on-primary;

  &.warning {
    background: $error;
  }
}

label.mat-checkbox-layout {
  white-space: break-spaces;
}
.mat-checkbox-label {
  position: relative;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba($black-rgb, 0.38);
}

.color-picker {
  left: 80px !important;
}

.mat-stroked-button {
  background: var(--white);
  color: var(--black);
  &.filled-btn {
    &,
    &:hover {
      background: var(--primary);
      color: var(--text-on-primary);
    }
  }
}

.mat-expansion-panel-header-title {
  color: var(--black);
}

.mat-datepicker-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
 stroke: var(--primary); 
}

.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  &.primary-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//#endregion Material

//#region Mapbox
.mapboxgl-ctrl-geocoder {
  background: transparent;
}

.mapboxgl-ctrl-geocoder--input {
  background: rgba($white-rgb, 0.5);
  color: $black;

  &:focus,
  &:hover {
    background: $white;
  }
}
.mapboxgl-ctrl-geocoder--button {
  background-color: transparent;
  .mapboxgl-ctrl-geocoder--icon-close {
    fill: $black;
  }
}

.mapboxgl-ctrl-group {
  background: $white;
}

.dark-theme {
  .mapboxgl-ctrl-group button {
    span {
      filter: invert(0.5);
    }
  }
}
.ngx-mat-timepicker form {
  justify-content: center;
  display: flex;
}
//#endregion Mapbox

//#region Ladda

.ladda-button {
  font-size: 14px;

  &:not(.filled-btn):hover {
    background-color: var(--white);
    color: var(--primary);
  }
  &.filled-btn:hover {
    color: var(--white);
    background-color: var(--primary);
  }

  &[data-loading] {
    background: rgb(var(--primary-rgb), 0.3) !important;
  }
}
//#endregion Ladda

// #region underlined link

a.underlined {
  position: relative;
}
a.underlined:after {
  bottom: -2px;
  content: '';
  display: block;
  height: 1.5px;
  left: 0;
  position: absolute;
  background: var(--primary);
  opacity: 0.5;
  transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
  width: 0;
}

a.underlined:hover:after {
  width: 100%;
  opacity: 0.9;
}
// #endregion underlined link
.square {
  position: relative;

  &::after {
    content: '2';
    position: absolute;
    font-size: 9px;
    top: -5px;
  }
}
.cube {
  position: relative;

  &::after {
    content: '3';
    position: absolute;
    font-size: 9px;
    top: -5px;
  }
}